import React, { useState } from "react";
import LierniImage from "../images/Lierni.jpg";
import "../styles/layout.css";
import "../styles/body-curriculum.css";
import "../styles/pdf-button.css";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const Curriculum = () => {
  const [loader, setLoader] = useState(false);

  const downloadPDF = async () => {
    const capture = document.querySelector(".main-container");
    setLoader(true);

    const downloadButton = document.querySelector(".cv-download-button");
    downloadButton.classList.add("pdf-download-in-progress");

    try {
      if (window.innerWidth > 830) {
        // Código para pantallas normales
        downloadButton.style.display = "none";

        const canvas = await html2canvas(capture, { scale: 2 });
        const imgData = canvas.toDataURL("img/jpg");
        const doc = new jsPDF("a4");
        const componentWidth = doc.internal.pageSize.getWidth();
        const componentHeight = doc.internal.pageSize.getHeight();

        doc.addImage(imgData, "JPG", 0, 0, componentWidth, componentHeight);
        doc.save("Curriculum.pdf");
      } else {
        // Código para pantallas móviles
        const scale = 2;
        const canvas = await html2canvas(capture, {
          width: capture.scrollWidth * scale,
          height: capture.scrollHeight * scale,
          scale: scale,
        });
        const imgData = canvas.toDataURL("image/jpg");

        downloadButton.style.display = "none";

        const options = {
          filename: "Curriculum_mobile.pdf",
          image: { type: "jpg", data: imgData },
          html2canvas: { scale: scale },
          jsPDF: { orientation: "portrait" },
          margin: [0, 0, 0, 0],
          y: 0,
        };

        // Generar y guardar el PDF
        await html2pdf().set(options).from(capture).save();
      }
    } catch (error) {
      console.error("Error durante la generación del PDF:", error);
    } finally {
      downloadButton.style.display = "block";

      setLoader(false);
      downloadButton.classList.remove("pdf-download-in-progress");
    }
  };

  return (
    <div className="main-container">
      <div className="cv-container">
        <div className="sidebar">
          <img className="lierni" src={LierniImage} alt="Lierni.jpg" />
          <div className="cv-sidebar-contact">
            <h2 className="datos">Datos Personales</h2>
            <hr />
            <p>
              <strong>Dirección:</strong> 48230 Elorrio (Bizkaia)
            </p>
            <p>
              <strong>Email:</strong> informatikalierni@gmail.com
            </p>
            <p>
              <strong>Personal Portfolio: </strong>
              <a
                href="https://www.liernimurgoitio.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                liernimurgoitio.net
              </a>
            </p>
            <p></p>
            <p>
              <strong>LinkedIn: </strong>
              <a
                href="https://www.linkedin.com/in/liernimurgoitio"
                target="_blank"
                rel="noopener noreferrer"
              >
                linkedin.com/in/liernimurgoitio
              </a>
            </p>
            <p>
              <strong>GitHub: </strong>
              <a
                href="https://www.github.com/liernimurgoitio"
                target="_blank"
                rel="noopener noreferrer"
              >
                github.com/liernimurgoitio
              </a>
            </p>
          </div>

          <div className="languages">
            <h2>Idiomas</h2>
            <hr />

            <p>
              <strong>Euskera:</strong> Competencia bilingüe o nativa.
            </p>
            <p>
              <strong>Español:</strong> Competencia bilingüe o nativa.
            </p>
            <p>
              <strong>Inglés:</strong> Competencia profesional completa.
            </p>
            <p>
              <strong>Francés:</strong> Competencia básica.
            </p>
          </div>

          <div className="resumen">
            <h2>Resumen Laboral</h2>
            <hr />
            <p>
              Profesional con experiencia previa en administración y una sólida
              transición hacia el sector tecnológico, impulsada por una pasión
              por el desarrollo web y la ciberseguridad.
            </p>
            <p>
              En 2018, inicié mi formación técnica en el ámbito de las
              telecomunicaciones e informática, obteniendo el título de Técnica
              Superior en Sistemas de Telecomunicaciones e Informáticos en
              Armeria Eskola, Eibar. Posteriormente, completé con éxito el
              programa intensivo de Full Stack Development (600 horas) organizado
              por Lanbide, en colaboración con la Fundación Vass y Bottega,
              donde adquirí habilidades avanzadas en desarrollo web y programación.
            </p>
            <p>
              Actualmente, estoy realizando la Especialización en Ciberseguridad en Entornos de las
              Tecnologías de la Información, fortaleciendo mi perfil para enfrentar
              desafíos relacionados con la seguridad tecnológica y la protección
              de sistemas informáticos.
            </p>
          </div>
        </div>

        <div className="main-content">
          <div className="head-content">
            <h2 className="name">Lierni Murgoitio Echabarri</h2>
            <button
              className="cv-download-button"
              onClick={downloadPDF}
              disabled={loader}
            >
              {loader ? "Descargando PDF..." : "Descargar PDF"}
            </button>
          </div>
          <hr />

          <div className="curriculum-page">
            <p>
              Técnica Superior en Sistemas de Telecomunicaciones e Informáticos, con formación
              en Full Stack Development y actualmente especializándome en Ciberseguridad en
              Entornos de Tecnologías de la Información. Cuento con habilidades técnicas avanzadas
              y capacidad para aprender y aplicar conocimientos al ámbito profesional.
              Estoy en busca de una oportunidad laboral donde desarrollar mis habilidades y adquirir
              experiencia en el sector tecnológico y de ciberseguridad.
            </p>
          </div>

          <div className="skills">
            <h2>Habilidades</h2>
            <hr />
            <div className="skills-container">
              <div className="skill-item-front">Front End</div>
              <div className="skill-icon-front">
                <div className="icons">
                  <img
                    className="icon-image"
                    src={require("../images/html_icon.jpg")}
                    alt="HTML5"
                  />
                  <span />
                  HTML
                </div>

                <div className="icons">
                  <img
                    className="icon-image"
                    src={require("../images/css3_icon.jpg")}
                    alt="CSS3"
                  />
                  <span />
                  CSS
                </div>

                <div className="icons">
                  <img
                    className="icon-image"
                    src={require("../images/javascript_icon.jpg")}
                    alt="JS"
                  />
                  <span />
                  JS
                </div>

                <div className="icons">
                  <img
                    className="icon-image"
                    src={require("../images/react_icon.jpg")}
                    alt="React"
                  />
                  <span />
                  React
                </div>
              </div>

              <div className="skill-item-back">Back End</div>
              <div className="skill-icon-back">
                <div className="icons">
                  <img
                    className="icon-image"
                    src={require("../images/python_icon.jpg")}
                    alt="Python"
                  />
                  <span />
                  Python
                </div>

                <div className="icons">
                  <img
                    className="icon-image"
                    src={require("../images/nodejs_icon.jpg")}
                    alt="Node"
                  />
                  <span />
                  Node
                </div>
              </div>

              <div className="skill-item-data">Database</div>
              <div className="skill-icon-data">
                <div className="icons">
                  <img
                    className="icon-image"
                    src={require("../images/mysql_icon.jpg")}
                    alt="Mysql"
                  />
                  <span />
                  Mysql
                </div>

                <div className="icons">
                  <img
                    className="icon-image"
                    src={require("../images/mongodb_icon.jpg")}
                    alt="MongoDB"
                  />
                  <span />
                  MongoDB
                </div>
              </div>

              <div className="skill-item-addition">Additional Skills</div>
              <div className="skill-icon-addition">
                <div className="icons">
                  <img
                    className="icon-image"
                    src={require("../images/git_icon.jpg")}
                    alt="Git"
                  />
                  <span />
                  Git
                </div>

                <div className="icons">
                  <img
                    className="icon-image"
                    src={require("../images/github_icon.jpg")}
                    alt="Github"
                  />
                  <span />
                  Github
                </div>
              </div>

              <div className="skill-item-cybersecurity">Cybersecurity</div>
              <div className="skill-icon-cybersecurity">
                <div className="icons">
                  <img
                    className="icon-image"
                    src={require("../images/kali_icon.png")}
                    alt="Kali Linux"
                  />
                  <span />
                  Kali Linux
                </div>

                <div className="icons">
                  <img
                    className="icon-image"
                    src={require("../images/metasploit_icon.png")}
                    alt="Metasploit"
                  />
                  <span />
                  Metasploit
                </div>

                <div className="icons">
                  <img
                    className="icon-image"
                    src={require("../images/wireshark_icon.png")}
                    alt="Wireshark"
                  />
                  <span />
                  Wireshark
                </div>

                <div className="icons">
                  <img
                    className="icon-image"
                    src={require("../images/nmap_icon.png")}
                    alt="Nmap"
                  />
                  <span />
                  Nmap
                </div>
              </div>
            </div>

          </div>

          <div className="education">
            <h2>Educación:</h2>
            <hr />
            <h5>Maristak Ikastetxea</h5>
            <p>ESPECIALIZACIÓN EN CIBERSEGURIDAD EN ENTORNOS DE LAS TECNOLOGÍAS DE LA INFORMACIÓN</p>
            <p>Fecha: Actualidad</p>

            <h5>Bottega University</h5>
            <p>CERTIFICATE OF COMPLETION OF FULL STACK PROGRAM 2023</p>
            <p>Fecha: Enero 2023 - Diciembre 2023</p>

            <h5>Bottega University</h5>
            <p>CODING FOUNDATIONS</p>
            <p>Fecha: Octubre 2022 - Diciembre 2022</p>

            <h5>Armeria Eskola</h5>
            <p>
              TECNICA SUPERIOR DE SISTEMAS DE TELECOMUNICACIONES E INFORMATICOS
            </p>
            <p>Fecha: Septiembre 2018 - Junio 2020</p>
          </div>

          <div className="otros-certificados">
            <h2>Educación Complementaria</h2>
            <hr />
            <p>2024-05: Python Full Stack. (IBM SkillsBuild - BeJob-60 horas)</p>
            <p>2022-12: Seguridad informática. (Lanbide-420 horas)</p>

            <p>
              2022-02: OSINT: Espionaje Digital Avanzado. Realiza
              Ciberpatrullaje. (Udemy-9.5 horas)
            </p>

            <p>
              2022-01: Excel Completo – Desde Principiante hasta Avanzado.
              (Udemy-11.5 horas)
            </p>

            <p>2021-11: GitHub para Programadores. (Linkedin-1h 53 min)</p>

            <p>
              2021-10: Angular & Spring Boot: Creando Web App Full Stack.
              (Udemy-23.5 horas)
            </p>

            <p>
              2020-10: Sistemas Microinformáticos. (Lanbide – Ipartek Formación-
              560 horas)
            </p>

            <p>2020-06: Desarrollo Avanzado en PHP. (Lanbide-40 horas)</p>

            <p>
              2020-06: Aplicaciones de Oracle para Datamining y Big Data.
              (Sepe-150 horas)
            </p>

            <p>2020-06: Ciberseguridad para Usuarios. (Sepe-10 horas)</p>
          </div>


        </div>
      </div>
    </div>
  );
};

export default Curriculum;
